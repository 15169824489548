body {
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.AboutMeContainer {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 800px) {
  .AboutMeContainer {
    flex-direction: column;
  }
}

.AboutMeDescription {
  font-family: Cambria, Georgia, serif;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  line-height: 20px;
  font-size: 1.33em; 
  width: 40%;
}

@media (max-width: 800px) {
  .AboutMeDescription {
    flex-direction: column;
    width: 80%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}




/* .test {
  background: orange;
  -webkit-clip-path: polygon(96% 4%, 100% 79%, 0 100%, 0 0);
  clip-path: polygon(96% 4%, 100% 79%, 0 100%, 0 0);
  transition: 0.3s ease-in-out;
}

.test:hover {

  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  transition: 0.3s ease-in-out;

} */